<script lang="ts">
    import { getContext } from 'svelte';
    import * as Table from '$lib/components/ui/table';

    interface Transaction {
        buyer: { name: string };
        seller: { name: string };
        sent_amount: number;
        CardIcontransaction_fee: number;
        created_at: string;
    }

    interface WalletTransactionContext {
        referral_transactions: Transaction[];
        post_transactions: Transaction[];
    }

    const walletTransactionContext = getContext<WalletTransactionContext>('walletTransactionContext');
    const { referral_transactions, post_transactions } = walletTransactionContext;

    const allTransactions = [
        ...referral_transactions.map((t) => ({ ...t, type: 'Referral' as const })),
        ...post_transactions.map((t) => ({ ...t, type: 'Post' as const })),
    ].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
</script>

<Table.Root>
    <Table.Caption>All Transactions</Table.Caption>
    <Table.Header>
        <Table.Row>
            <Table.Head>Type</Table.Head>
            <Table.Head>Buyer</Table.Head>
            <Table.Head>Seller</Table.Head>
            <Table.Head>Amount</Table.Head>
            <Table.Head>Transaction Fee</Table.Head>
            <Table.Head>Created At</Table.Head>
        </Table.Row>
    </Table.Header>
    <Table.Body>
        {#each allTransactions as transaction}
            <Table.Row>
                <Table.Cell>{transaction.type}</Table.Cell>
                <Table.Cell>{transaction.buyer.name}</Table.Cell>
                <Table.Cell>{transaction.seller.name}</Table.Cell>
                <Table.Cell>{transaction.sent_amount}</Table.Cell>
                <Table.Cell>{transaction.CardIcontransaction_fee}</Table.Cell>
                <Table.Cell>{new Date(transaction.created_at).toLocaleString()}</Table.Cell>
            </Table.Row>
        {/each}
    </Table.Body>
</Table.Root>
